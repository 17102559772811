<template>
    <div class="login-space">
        <div class="login">
            <img :class="{'bg-img': true, 'bg-img-show': bgLoad}" src="../assets/lang.png" alt="" @load="bgLoad = true">
            <div class="login-box">
                <p :class="{'login-switch': true, 'login-switch-register': type === 2 ? true : false, 'login-switch-forget': type === 3 ? true : false}">
                    <span @click="switchType(1)">登录</span>
                    <span @click="switchType(2)">注册</span>
                    <span v-if="isShowForget && type === 3" @click="switchType(3)">忘记密码</span>
                </p>
                <div class="form-item">
                    <label>邮箱</label>
                    <input id="login-account" :autocomplete="type === 1 ? 'on' : 'new-password'" name="account" type="text" :placeholder="type === 1 || type === 3 ? '请输入已经注册的邮箱' : '请输入用于注册的邮箱'" v-model="form.account">
                </div>
                <div :class="{'forget-box': true, 'forget-box-show': type === 3 ? true : false}">
                    <div class="form-item">
                        <label>新密码</label>
                        <input type="password" maxlength="20" :autocomplete="type === 3 ? 'on' : 'new-password'" name="password" placeholder="请输入新密码" v-model="form.newPassword">
                    </div>
                </div>
                <div class="form-item password-item">
                    <label>{{type === 3 ? '确认密码' : '密码'}}</label>
                    <input :type="showPassword ? 'text' : 'password'" maxlength="20" :autocomplete="type === 1 ? 'on' : 'new-password'" name="password" :placeholder="type === 3 ? '请再次输入新密码' : '请输入密码'" v-model="form.password">
                    <i v-if="form.password && type !== 3" @click="showPassword = !showPassword" :class="['iconfont', showPassword ? 'icon-showpassword' : 'icon-hiddenpassword']"></i>
                </div>
                <div class="form-item">
                    <label>图片验证码</label>
                    <div class="form-item-inner">
                        <input type="text" maxlength="4" v-model="form.captcha" placeholder="验证码不区分大小写" @keyup.enter="enterLogin">
                        <div v-html="'<div class=\'captcha-refresh\'>点击刷新</div>' + captchaImg" class="captcha" @click="loadCaptcha"></div>
                    </div>
                </div>
                <div :class="{'rigister-box': true, 'rigister-box-show': type === 2 || type === 3 ? true : false}">
                    <div class="form-item">
                        <label>邮箱验证码</label>
                        <div class="form-item-inner">
                            <input type="text" maxlength="6" v-model="form.mailCaptcha" @keyup.enter="enterSendMail">
                            <button class="btn btn-red mail-captcha" @click="sendMailCaptcha" :disabled="typeof sendCooling === 'string' ? false : true">{{typeof sendCooling === 'string' ? sendCooling : (sendCooling + '秒后可重发')}}</button>
                        </div>
                    </div>
                </div>

                <button :class="'btn btn-' + (type === 1 ? 'green' : (type === 2 ? 'blue' : 'red')) + '-full btn-block login-btn'" @click="submit">{{type === 1 ? '登录' : (type === 2 ? '注册' : '找回密码')}}</button>
                <button :class="{'forget-btn': true, 'forget-btn-show': !isShowForget}" @click="showForget">忘记密码?</button>
            </div>
            <div class="close" @click="close">
                <i class="iconfont icon-close"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Login",
    data() {
        return {
            form: {
                account: "",
                newPassword: "",
                password: "",
                captcha: "",
                mailCaptcha: "",
            },
            type: 1,
            captchaImg: "",
            sendCooling: "发送",
            isShowForget: false,
            bgLoad: false,
            showPassword: false,
        };
    },
    created() {
        this.type = this.$route.query.type ? Number(this.$route.query.type) : 1;
        this.loadCaptcha();
    },
    mounted() {
        document.getElementById("login-account").focus();
    },
    methods: {
        // 更新验证码
        loadCaptcha() {
            let that = this;
            that.$({
                url: "/user/captcha",
                type: "POST",
                success(res) {
                    that.captchaImg = res.data;
                },
                error(res) {
                    that.$toast(res.message, "error");
                },
                loading: false,
            });
        },
        // 发送邮箱验证码
        sendMailCaptcha() {
            let that = this;
            if (!that.form.account && !that.form.captcha) {
                that.$toast("请正确输入邮箱和验证码", "warn");
                return false;
            }
            that.$({
                url: "/user/captcha_mail",
                data: {
                    account: that.form.account,
                    captcha: that.form.captcha,
                    type: that.type === 3 ? 1 : "",
                },
                type: "POST",
                success() {
                    that.$toast("发送成功，请注意查收", "success");
                    that.sendCooling = 60;
                    let timer = setInterval(() => {
                        that.sendCooling--;
                        if (that.sendCooling < 0) {
                            that.sendCooling = "重新发送";
                            clearInterval(timer);
                        }
                    }, 1000);
                },
                error(res) {
                    if (res.status == 100101) {
                        that.loadCaptcha();
                        that.form.captcha = "";
                    }
                    that.$toast(res.message, "error");
                },
            });
        },
        // 登录注册
        submit() {
            let that = this;
            if (!that.$base.regMail.test(that.form.account)) {
                that.$toast("邮箱格式错误", "warn");
                return false;
            }
            let url = "";
            let data = {};
            // 登录
            if (that.type == 1) {
                if (!that.form.account) {
                    that.$toast("请正确输入邮箱", "warn");
                    return false;
                }
                if (!that.form.password) {
                    that.$toast("请正确输入密码", "warn");
                    return false;
                }
                if (!that.$base.regPwd.test(that.form.password)) {
                    that.$toast(
                        "密码为大于6位的字母/数字/特殊字符的任意两种组合",
                        "warn"
                    );
                    return false;
                }
                if (!that.form.captcha) {
                    that.$toast("请正确输入图片验证码", "warn");
                    return false;
                }
                url = "/user/login";
                data = {
                    account: that.form.account,
                    password: that.form.password,
                    captcha: that.form.captcha,
                };
                that.$({
                    url: url,
                    data: data,
                    type: "POST",
                    success(res) {
                        that.$toast("登录成功", "success");
                        that.$base.setCookie("token", res.data.token, 30);
                        that.$router.replace("/");
                        // 刷新登陆状态
                        that.$({
                            url: "user/info",
                            success(res) {
                                // 使用服务器保存的设置
                                if (res.data.setting) {
                                    let settingParse = JSON.parse(
                                        res.data.setting
                                    );
                                    let defaultSetting = {
                                        theme: 1,
                                        fontSize: 16,
                                        sort: false,
                                        logo: true,
                                        daytop: false,
                                        hotItemComplete: false,
                                        quitelyMo: false,
                                        workTime: {
                                            isOpen: false,
                                            range: [480, 1080],
                                            unit: 0,
                                            wages: 1800,
                                            workDay: 21.75,
                                        },
                                    };
                                    // 如果服务器端保存的设置有问题
                                    if (
                                        !(
                                            settingParse &&
                                            typeof settingParse.theme ==
                                                "number" &&
                                            typeof settingParse.sort ==
                                                "boolean" &&
                                            typeof settingParse.logo ==
                                                "boolean" &&
                                            typeof settingParse.daytop ==
                                                "boolean" &&
                                            typeof settingParse.hotItemComplete ==
                                                "boolean" &&
                                            typeof settingParse.quitelyMo ==
                                                "boolean" &&
                                            typeof settingParse.fontSize ==
                                                "number" &&
                                            typeof settingParse.workTime ==
                                                "object" &&
                                            typeof settingParse.workTime
                                                .isOpen == "boolean" &&
                                            typeof settingParse.workTime
                                                .range == "object" &&
                                            typeof settingParse.workTime.unit ==
                                                "number" &&
                                            typeof settingParse.workTime
                                                .wages == "number" &&
                                            typeof settingParse.workTime
                                                .workDay == "number"
                                        )
                                    ) {
                                        // 以默认为基础合并服务器数据过来
                                        settingParse = Object.assign(
                                            defaultSetting,
                                            settingParse
                                        );
                                    }
                                    that.$store.commit("setting", settingParse);
                                    localStorage.setItem(
                                        "setting",
                                        JSON.stringify(settingParse)
                                    );
                                    delete res.data.setting;
                                }
                                that.$store.commit("user", res.data);
                            },
                            error(res) {
                                // 登录失效则清空token和页面用户信息
                                if (res.status && res.status == 100404) {
                                    that.$store.commit("user", {});
                                    that.$base.setCookie("token", "", -1);
                                }
                            },
                            loading: false,
                        });
                    },
                    error(res) {
                        that.$toast(res.message, "error");
                        that.loadCaptcha();
                        that.form.captcha = "";
                    },
                });
            }
            // 注册
            else if (that.type === 2) {
                if (!that.form.account) {
                    that.$toast("请正确输入邮箱", "warn");
                    return false;
                }
                if (!that.form.password) {
                    that.$toast("请正确输入密码", "warn");
                    return false;
                }
                if (!that.$base.regPwd.test(that.form.password)) {
                    that.$toast(
                        "密码为大于6位的字母/数字/特殊字符的任意两种组合",
                        "warn"
                    );
                    return false;
                }
                if (!that.form.mailCaptcha) {
                    that.$toast("请正确输入邮箱验证码", "warn");
                    return false;
                }
                url = "/user/register";
                data = {
                    account: that.form.account,
                    password: that.form.password,
                    captcha: that.form.mailCaptcha,
                };
                that.$({
                    url: url,
                    data: data,
                    type: "POST",
                    success() {
                        that.$toast("注册成功", "success");
                        that.form.captcha = "";
                        that.form.mailCaptcha = "";
                        that.loadCaptcha();
                        that.type = 1;
                    },
                    error(res) {
                        that.$toast(res.message, "error");
                        if (res.status == 100002) {
                            that.form.mailCaptcha = "";
                        }
                    },
                });
            } else {
                if (!that.form.account) {
                    that.$toast("请正确输入邮箱", "warn");
                    return false;
                }
                if (!that.form.newPassword) {
                    that.$toast("请正确输入密码", "warn");
                    return false;
                }
                if (!that.$base.regPwd.test(that.form.newPassword)) {
                    that.$toast(
                        "密码为大于6位的字母/数字/特殊字符的任意两种组合",
                        "warn"
                    );
                    return false;
                }
                if (that.form.password != that.form.newPassword) {
                    that.$toast("两次输入的密码不一致", "warn");
                    return false;
                }
                if (!that.form.mailCaptcha) {
                    that.$toast("请正确输入邮箱验证码", "warn");
                    return false;
                }
                url = "/user/forget";
                data = {
                    account: that.form.account,
                    password: that.form.password,
                    captcha: that.form.mailCaptcha,
                    password_confirm: that.form.newPassword,
                };
                that.$({
                    url: url,
                    data: data,
                    type: "POST",
                    success() {
                        that.$toast("修改密码成功", "success");
                        that.form.captcha = "";
                        that.form.mailCaptcha = "";
                        that.loadCaptcha();
                        that.type = 1;
                        that.isShowForget = false;
                    },
                    error(res) {
                        that.$toast(res.message, "error");
                        if (res.status == 100002) {
                            that.form.mailCaptcha = "";
                        }
                    },
                });
            }
        },
        // 切换
        switchType(type) {
            if (type == 1) this.isShowForget = false;
            if (type == 2) this.isShowForget = true;

            this.type = type;
            document.getElementById("login-account").focus();
        },
        // 关闭
        close() {
            this.$router.go(-1);
        },
        enterLogin() {
            this.type === 1 ? this.submit() : this.sendMailCaptcha();
        },
        enterSendMail() {
            this.type === 2 ? this.submit() : "";
        },
        showForget() {
            this.type = 3;
            this.isShowForget = true;
        },
    },
};
</script>

<style lang="less">
.login-space {
    width: 100%;
    height: 100%;
}

.login {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #282c34;
    z-index: 2;

    > .bg-img {
        position: absolute;
        width: 850px;
        bottom: 0;
        left: 0;
        transform: translate(-100%, 100%);
        z-index: 1;
        transition: transform 0.8s;
    }

    > .bg-img-show {
        transform: translate(-160px, 273px);
    }
}

@keyframes gradientBG {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.close {
    position: absolute;
    padding: 5px;
    right: 20px;
    top: 20px;
    cursor: pointer;
    color: #fff;

    > i {
        font-size: 24px;
    }
}

.login-box {
    width: 400px;
    padding: 60px 40px;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 12px 12px 0px 0px #262626;
    z-index: 2;
}

.login-switch {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 40px;
    font-size: 20px;

    > span {
        color: #ccc;
        cursor: pointer;
        padding: 0 2px 4px 2px;
        border-bottom: 2px solid transparent;
        transition: color 0.3s, border-color 0.3s;
    }

    > span:first-of-type {
        color: #444;
        border-color: #5983ce;
    }
}

.login-switch-register {
    > span:first-of-type {
        color: #ccc;
        border-color: transparent;
    }

    > span:last-of-type {
        color: #444;
        border-color: #5983ce;
    }
}

.login-switch-forget {
    > span:first-of-type {
        color: #ccc;
        border-color: transparent;
    }

    > span:nth-of-type(2) {
        color: #ccc;
        border-color: transparent;
    }

    > span:last-of-type {
        color: #444;
        border-color: #5983ce;
    }
}

.form-item {
    width: 100%;
    margin-bottom: 25px;

    label {
        color: #aaa;
        display: inline-block;
        margin-bottom: 2px;
    }

    input {
        display: block;
        width: 100%;
        padding: 7px 0;
        font-size: 16px;
        color: #666;
        border-bottom: 1px solid #bbb;
        transition: border-color 0.3s;

        &:focus {
            border-color: #49add0;

            &::-webkit-input-placeholder {
                color: #ccc;
            }
        }

        &::-webkit-input-placeholder {
            color: #fff;
            transition: color 0.3s;
        }
    }
}

.form-item-inner {
    width: 100%;

    input {
        width: calc(100% - 140px);
        display: inline-block;
    }

    .captcha {
        position: relative;
        width: 120px;
        display: inline-block;
        vertical-align: bottom;
        cursor: pointer;
        font-size: 0;
        margin-left: 10px;

        > .captcha-refresh {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            font-size: 14px;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2px;
            background-color: rgba(0, 0, 0, 0.4);
            opacity: 0;
            transition: opacity 0.3s;
        }

        &:hover > .captcha-refresh {
            opacity: 1;
        }
    }

    .mail-captcha {
        margin: 0 10px;
        width: 120px;
        vertical-align: bottom;
    }
}

.password-item {
    position: relative;
    i {
        position: absolute;
        top: 18px;
        right: 5px;
        font-size: 20px;
        padding: 5px;
        cursor: pointer;
    }
    .icon-hiddenpassword {
        color: #bbb;
    }
    .icon-showpassword {
        color: #999;
    }
}

.login-btn {
    margin: 0;
    padding: 10px 0;
}

.rigister-box,
.forget-box {
    max-height: 0;
    transition: max-height 0.3s linear;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
}

.rigister-box-show,
.forget-box-show {
    max-height: 80px;
    opacity: 1;
    visibility: visible;
}

.forget-btn {
    margin: 10px auto 0;
    color: #444;
    background-color: transparent;
    display: block;
    opacity: 0;
    transition: opacity 0.3s;
}

.forget-btn-show {
    opacity: 1;
    cursor: pointer;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
    display: none;
}

// 移动端兼容
@media screen and (max-width: 768px) {
    .login {
        padding: 20px;
    }

    .login-box {
        width: auto;
        padding: 40px;
        font-size: 14px;
    }

    .form-item input {
        font-size: 14px;
    }
}
</style>